
function handleToolsetSlider(){

    const toolsetSlider = document.getElementById("toolsetSlider");

    const toolsetLeftArrow = document.getElementById("toolsetLeftArrow");
    
    const toolsetRightArrow = document.getElementById("toolsetRightArrow");
    
    toolsetLeftArrow.addEventListener('click',(event)=>{
        event.preventDefault();
    
        toolsetSlider.scrollLeft -=100; 
    })
    
    toolsetRightArrow.addEventListener('click',(event)=>{
        event.preventDefault();
    
        toolsetSlider.scrollLeft +=100;
    });
}

function handlebasePageTestimonialSlider(){
    const testimonialSlider = document.getElementById("basePageTestimonialList");

    const testimonialLeftArrow = document.getElementById("basePageTestimonialLeftArrow");
    
    const testimonialRightArrow = document.getElementById("basePageTestimonialRightArrow");

    const testimonialListItemWidth = document.getElementById("testimonialListItemCard");
    
    testimonialLeftArrow.addEventListener('click',(event)=>{
        event.preventDefault();

        testimonialSlider.scrollLeft -=testimonialListItemWidth.offsetWidth; 
    })
    
    testimonialRightArrow.addEventListener('click',(event)=>{
        event.preventDefault();

        testimonialSlider.scrollLeft +=testimonialListItemWidth.offsetWidth;
    });
}

function handleLetsTalkButtonAction(){

    const letsTalkButtonCareer = document.getElementById("letsTalkButton");

    const handleGetInTouch = document.getElementById("getInTouchId");

    function handleWindowScroll(event){
        event.preventDefault();
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth'
        });
    }

    letsTalkButtonCareer.addEventListener('click',handleWindowScroll);
    handleGetInTouch.addEventListener('click', handleWindowScroll);
}

function handleFlowStepsActions(){

    let currentStep=1;

    const flowStepsListContainer = document.getElementById("flowStepListContainerId");

    const flowStepListItem = document.getElementById("flowStepListItemId");

    let flowStepItems =[];

    for(let i=1;i<=4;i++){
        flowStepItems.push(['flowStepsLeftArrowId'+i,"flowStepsRightArrowId"+i])
    }

    flowStepItems.forEach((items)=>{
        const [left,right] = items;
        const flowStepLeftArrow = document.getElementById(left);
        const flowStepRightArrow = document.getElementById(right);

        flowStepLeftArrow.addEventListener("click",(event)=>{
            event.preventDefault();
    
            currentStep=currentStep-1;
    
            flowStepsListContainer.scrollLeft-= flowStepListItem.offsetWidth +16;
    
        });
    
        flowStepRightArrow.addEventListener('click',(event)=>{
            event.preventDefault();
    
            currentStep=currentStep+1;
    
            flowStepsListContainer.scrollLeft+= flowStepListItem.offsetWidth +16;
    
        });

    })

    if(window.innerWidth<=800){
        setInterval(()=>{

            if(currentStep%4==0){
    
                currentStep=1;
    
                flowStepsListContainer.scrollTop = 0;
                return;
            }
    
            currentStep=currentStep+1;
        
            flowStepsListContainer.scrollTop+=800;
    
        },5000);
    }
    else{
        setInterval(()=>{

            if(currentStep%4==0){
    
                currentStep=1;
    
                flowStepsListContainer.scrollLeft = 0;
                return;
            }
    
            currentStep=currentStep+1;
        
            flowStepsListContainer.scrollLeft+= flowStepListItem.offsetWidth +16;
    
        },5000);    
    }

}

function handleFlowStepsActionsMb(){

    let currentStep=1;

    const flowStepsListContainer = document.getElementById("flowStepListContainerId");

    const flowStepListItem = document.getElementById("flowStepListItemId");

    let flowStepItems =[];

    for(let i=1;i<=4;i++){
        flowStepItems.push(['flowStepsLeftArrowIdMb'+i,"flowStepsRightArrowIdMb"+i])
    }

    flowStepItems.forEach((items)=>{
        const [left,right] = items;
        const flowStepLeftArrow = document.getElementById(left);
        const flowStepRightArrow = document.getElementById(right);

        flowStepLeftArrow.addEventListener("click",(event)=>{
            event.preventDefault();
    
            currentStep=currentStep-1;
    
            flowStepsListContainer.scrollTop-= 800;

        });
    
        flowStepRightArrow.addEventListener('click',(event)=>{
            event.preventDefault();
    
            currentStep=currentStep+1;
    
            flowStepsListContainer.scrollTop+= 800;
    
        });

    })

}

function handleFormSubmitAction(){
    const formSubmitButton = document.getElementById("basePageSubmitButton");
    let formData={};

    const formName = document.getElementById("baseFormNameId");
    formName.addEventListener('change',(event)=>{
        event.preventDefault();
        formData.name= event.target.value;
    });

    const formEmail = document.getElementById('baseFormEmailId');
    formEmail.addEventListener('change',(event)=>{
        event.preventDefault();
        formData.email = event.target.value;
    })

    const formContactDetails = document.getElementById("baseContactId");
    formContactDetails.addEventListener('change',(event)=>{
        event.preventDefault();
        formData.contact = event.target.value;
    })

    const formDescription = document.getElementById('baseProjectDetailsId');
    formDescription.addEventListener('change',(event)=>{
        event.preventDefault();
        formData.description = event.target.value;
    });

    formSubmitButton.addEventListener('click',(event)=>{
        event.preventDefault();
        const emailBody = 'Hi,%0A'+"I am "+(formData.name || "")+" and we wanted to have a discussion about a new Project.%0A"+"Descritption : "+(formData.description || "")+"%0A%0A"+"Regards,%0A"+(formData.name || "")+",%0A"+(formData.contact || "")
        const mainToContent = "mailto:ceo@sachhsoft.com"+'?&subject=New Development Project Request'+'&body='+emailBody;
        window.location.href = mainToContent
    })
}

function handleServicesExploreMore(){
    const exploreMoreButton = document.getElementById("basePageServiceExploreMoreButton");

    function handleExploreMore(){
        window.location.href = "service.html"
    }

    exploreMoreButton.addEventListener('click', handleExploreMore);
}

handleToolsetSlider();
handlebasePageTestimonialSlider();
handleLetsTalkButtonAction();
handleFlowStepsActions();
handleFlowStepsActionsMb();
handleFormSubmitAction();

handleServicesExploreMore();